<div *transloco="let t; read: 'data'">
    @if (dataManagementData()?.length === 0) {
        <fuse-alert
            [appearance]="'soft'"
            [type]="'basic'"
            [showIcon]="false"
            class="text-center block my-auto p-2 w-full">
            {{ t('noDataTablesFound') }}
        </fuse-alert>
    } @else {
        <div class="container-y--xs">
            <lib-data-management-tree
                [editMode]="editMode()"
                [selectedNodeId]="selectedNodeId()"
                [mdoSystems]="mdoSystems()"
                [dataManagementData]="dataManagementData()"
                [translocoContent]="translocoContent()"
                [canChangeTreeNodeSelection]="canChangeTreeNodeSelection.bind(this)"
                (treeNodeSelection)="treeNodeSelected($event)"
                (menuItemClick)="menuItemClicked($event)"></lib-data-management-tree>
        </div>
    }
</div>
