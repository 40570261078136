import {
    DestroyRef,
    Injectable,
    Signal,
    WritableSignal,
    computed,
    effect,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DataManagement, MdoAppDepartmentsInner } from 'app/api';
import { MDOService } from 'app/modules/master-data/master-data/mdo.service';
import { DATA_TABLE_CATEGORY } from './data.data';
import { DataManagementService } from './data-management.service';
import { cloneDeep, isEqual } from 'lodash';
import { SaveButtonService } from 'app/core/save-button/save-button.service';
import { DataManagementAPIService } from './data-management.api.service';

@Injectable({ providedIn: 'root' })
export class DataManagementTableDetailsFormService {
    private _tableDataForm: Signal<FormlyFieldConfig[]>;

    private _originalFormValue: WritableSignal<DataManagement> = signal(null);
    private _changedFormValue: WritableSignal<Partial<DataManagement>> = signal(null);
    private _validForm: WritableSignal<boolean> = signal(false);
    private _hasChanges: Signal<boolean>;

    private _translocoContent = signal(null);
    private _errors = signal(null);

    newEntry = false;

    constructor(
        private _translocoService: TranslocoService,
        private _mdoService: MDOService,
        private _destroyRef: DestroyRef,
        private _dataManagementService: DataManagementService,
        private _saveButtonService: SaveButtonService,
        private _apiService: DataManagementAPIService
    ) {
        // Getting transloco content data
        this._translocoService
            .selectTranslation('data')
            .pipe(takeUntilDestroyed())
            .subscribe(content => {
                this._translocoContent.set(content);
                this._errors.set(this._translocoService.translateObject('errors'));
            });

        // Setting table data form
        this._setTableDataForm();

        // setting original value of the form
        effect(
            () => {
                if (
                    this._originalFormValue()?.id ===
                    this._dataManagementService.selectedDataTable()?.id
                )
                    return;
                this.originalFormValue = cloneDeep(this._dataManagementService.selectedDataTable());
            },
            { allowSignalWrites: true }
        );

        // if the form has changes
        this._hasChanges = computed(() => {
            if (!this._dataManagementService.editableForm()) return false;
            let hasChanges = !isEqual(this.originalFormValue(), {
                ...this._dataManagementService.selectedDataTable(),
                ...this.changedFormValue(),
            });
            return hasChanges;
        });

        // show warn icon on save button on changes
        effect(
            () => {
                this._saveButtonService.hasUnsavedChanges = this.hasChanges();
            },
            { allowSignalWrites: true }
        );

        this._saveButtonService.continueWithoutSaving
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe({
                next: value => {
                    if (value === true) {
                        this.resetOriginalData();
                    }
                },
            });
    }

    resetOriginalData() {
        let currentData = this._apiService._data();
        const itemIndex = currentData.findIndex(item => item.id === this.originalFormValue()?.id);
        if (!itemIndex) return;

        currentData[itemIndex] = this.originalFormValue();
        this._apiService._data.update(() => [...currentData]);
    }

    get tableDataForm(): FormlyFieldConfig[] {
        return this._tableDataForm();
    }

    private _setTableDataForm() {
        this._tableDataForm = computed(() => [
            {
                fieldGroupClassName: 'flex flex-row gap-2 flex-wrap',
                fieldGroup: [
                    {
                        className: 'flex-1',
                        type: 'input',
                        key: 'wbs',
                        props: {
                            label: this._translocoContent()['wbs'],
                            required: true,
                        },
                        validation: this.getRequiredValidation(this._errors()['required']),
                    },
                    {
                        className: 'flex-1',
                        type: 'input',
                        key: 'version',
                        props: {
                            label: this._translocoContent()['version'],
                            required: true,
                        },
                        validation: this.getRequiredValidation(this._errors()['required']),
                    },
                ],
            },
            {
                fieldGroupClassName: 'flex flex-row gap-2 flex-wrap',
                fieldGroup: [
                    {
                        className: 'flex-1',
                        type: 'select',
                        key: 'category',
                        props: {
                            label: 'Category',
                            options: DATA_TABLE_CATEGORY,
                            required: true,
                        },
                        validation: this.getRequiredValidation(this._errors()['required']),
                    },
                    {
                        className: 'flex-1',
                        type: 'input',
                        key: 'titel',
                        props: {
                            label: this._translocoContent()['titel'],
                            required: true,
                        },
                        validation: this.getRequiredValidation(this._errors()['required']),
                    },
                ],
            },
            {
                className: 'flex-1',
                type: 'input',
                key: 'description',
                props: {
                    label: this._translocoContent()['description'],
                    required: true,
                },
                validation: this.getRequiredValidation(this._errors()['required']),
            },
            {
                fieldGroupClassName: 'flex flex-row gap-2 flex-wrap',
                fieldGroup: [
                    {
                        className: 'flex-1',
                        type: 'md-object-dialog',
                        key: 'system',
                        props: {
                            label: this._translocoContent()['system'],
                            required: true,
                            valueProp: 'id',
                            labelProp: 'title',
                            attributes: {
                                selectionType: 'system',
                            },
                        },
                        validation: this.getRequiredValidation(this._errors()['required']),
                    },
                    {
                        className: 'flex-1',
                        type: 'select',
                        key: 'responsible',
                        props: {
                            label: this._translocoContent()['owner'],
                            options: this._mdoService.owners,
                            required: true,
                            valueProp: 'id',
                            labelProp: 'abbr',
                        },
                        validation: this.getRequiredValidation(this._errors()['required']),
                    },
                ],
            },
            {
                fieldGroupClassName: 'grid md:grid-cols-2 sm:grid-cols-1 gap-2',
                fieldGroup: [
                    {
                        className: 'flex-1',
                        type: 'select',
                        key: 'status',
                        props: {
                            label: this._translocoContent()['status'],
                            options: this._mdoService.statuses.filter(status => {
                                if (!this.originalFormValue() || this.newEntry) return true;
                                return !(
                                    this.originalFormValue()?.status !== 'D' && status.value === 'D'
                                );
                            }),
                            required: true,
                        },
                        validation: this.getRequiredValidation(this._errors()['required']),
                    },
                    {
                        className: 'flex-1',
                        type: 'select',
                        key: 'auth',
                        props: {
                            label: 'Auth-Group',
                            options: this._mdoService.departments.map(
                                (account: MdoAppDepartmentsInner) => {
                                    return {
                                        value: account.id,
                                        label: account.name,
                                    };
                                }
                            ),
                            required: true,
                        },
                        validation: this.getRequiredValidation(this._errors()['required']),
                    },
                ],
            },
        ]);
    }

    getRequiredValidation(message: string): any {
        return {
            messages: {
                required: message,
            },
        };
    }

    public get originalFormValue(): Signal<DataManagement> {
        return this._originalFormValue.asReadonly();
    }

    public set originalFormValue(value: DataManagement) {
        this._originalFormValue.set(value);
    }

    public get changedFormValue(): Signal<Partial<DataManagement>> {
        return this._changedFormValue.asReadonly();
    }

    public set changedFormValue(value: Partial<DataManagement>) {
        this._changedFormValue.set(value);
    }

    public get hasChanges(): Signal<boolean> {
        return this._hasChanges;
    }

    public get validForm(): Signal<boolean> {
        return this._validForm.asReadonly();
    }

    public set validForm(value: boolean) {
        this._validForm.set(value);
    }
}
