import { Injectable, Signal, WritableSignal, computed, signal } from '@angular/core';
import { DataManagement } from 'app/api';
import { TableColumnDef } from '../../../../projects/grid-views/src/public-api';
import { DataManagementAPIService } from './data-management.api.service';

@Injectable({
    providedIn: 'root',
})
export class DataManagementService {
    private _state: Signal<DataManagement[]>;
    private _selectedDataTable: WritableSignal<DataManagement> = signal(null);

    // if the selected data table is in edit mode currently
    private _editableForm = signal<boolean>(false);

    private _selectedRow: WritableSignal<any> = signal(null);
    private _labelProp: WritableSignal<TableColumnDef> = signal(null);

    isSelectionDialog: boolean = false;

    constructor(private _dataService: DataManagementAPIService) {
        this._state = computed(() => this._dataService.data());
    }

    public get state(): Signal<DataManagement[]> {
        return this._state;
    }

    public get selectedDataTable(): Signal<DataManagement> {
        return this._selectedDataTable.asReadonly();
    }
    public set selectedDataTable(value: DataManagement) {
        this._selectedDataTable.set(value);
    }

    public get editableForm() {
        return this._editableForm.asReadonly();
    }
    public setEditableForm(status: boolean) {
        this._editableForm.set(status);
    }

    public get selectedRowSignal(): Signal<any> {
        return this._selectedRow;
    }

    public get selectedRow(): any {
        return this._selectedRow();
    }
    public set selectedRow(selectedRow: any) {
        this._selectedRow.set(selectedRow);
    }

    public get labelProp(): any {
        return this._labelProp();
    }
    public set labelProp(labelProp: TableColumnDef) {
        this._labelProp.set(labelProp);
    }
}
