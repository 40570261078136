import { Injectable, Injector, WritableSignal, effect, signal } from '@angular/core';
import {
    DataManagement,
    DataManagementExtra,
    DataManagementField,
    DataManagementService,
    DeleteTableFromDMById200Response,
    DmCostElementsService,
    DmTransactionsService,
    ScalaApiDbExporttablePost200Response,
} from 'app/api';
import { SaveButtonService } from 'app/core/save-button/save-button.service';
import { OrganisationFilterService } from 'app/layout/common/organisation-filter/organisation-filter.service';
import { SysService } from 'app/modules/org-sys-area/services/sys.service';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { BusinessProcessTaskService } from '../bp/business-process.service';

export interface BimApiService {
    updateDataTableList(
        organisation: string,
        dataManagement: Array<DataManagement>
    ): Observable<string>;
    backendApiFieldsReadFilePost(file?: Blob): Observable<Array<DataManagementField>>;
    backendApiDataManagementFieldsGenerateResponsePost(
        file?: Blob,
        data?: DataManagement
    ): Observable<Array<object>>;
    backendApiDataManagementFieldsExportPost(dataManagement: DataManagement): Observable<Blob>;
    downloadFieldsTemplate(): Observable<Blob>;
    getDataTableList(organisation: string, filter: string): Observable<Array<DataManagement>>;
    scalaApiDbGetdataPost(
        dataManagement: DataManagement,
        organisation: string,
        filterToSystem?: boolean
    ): Observable<any>;
    deleteTableFromDMById(
        id: string,
        organisation: string
    ): Observable<DeleteTableFromDMById200Response>;
    addNewTableToDM(
        organisation: string,
        dataManagement: DataManagement
    ): Observable<DataManagement>;
    scalaApiDbInsertdataPost(
        dataManagementExtra: DataManagementExtra,
        organisation?: string
    ): Observable<ScalaApiDbExporttablePost200Response>;
    scalaApiDbExporttablePost(
        dataManagement: DataManagement
    ): Observable<ScalaApiDbExporttablePost200Response>;
    scalaApiDbDeletetablePost(
        dataManagement: DataManagement,
        organisation?: string
    ): Observable<ScalaApiDbExporttablePost200Response>;
}

@Injectable({ providedIn: 'root' })
export class BimApiService1 implements BimApiService {
    constructor(private apiService: DataManagementService) {}

    updateDataTableList(
        organisation: string,
        dataManagement: Array<DataManagement>
    ): Observable<string> {
        return this.apiService.updateDataTableList(organisation, dataManagement);
    }

    scalaApiDbExporttablePost(
        dataManagement: DataManagement
    ): Observable<ScalaApiDbExporttablePost200Response> {
        return this.apiService.scalaApiDbExporttablePost(dataManagement);
    }

    backendApiFieldsReadFilePost(file?: Blob): Observable<DataManagementField[]> {
        return this.apiService.backendApiFieldsReadFilePost(file);
    }

    backendApiDataManagementFieldsGenerateResponsePost(
        file?: Blob,
        data?: DataManagement
    ): Observable<object[]> {
        return this.apiService.backendApiDataManagementFieldsGenerateResponsePost(file, data);
    }

    backendApiDataManagementFieldsExportPost(dataManagement: DataManagement): Observable<Blob> {
        return this.apiService.backendApiDataManagementFieldsExportPost(dataManagement);
    }

    downloadFieldsTemplate(): Observable<Blob> {
        return this.apiService.downloadFieldsTemplate();
    }

    getDataTableList(organisation: string, status: string): Observable<DataManagement[]> {
        return this.apiService.getDataTableList(organisation);
    }

    scalaApiDbGetdataPost(
        dataManagement: DataManagement,
        organisation: string,
        filterToSystem?: boolean
    ): Observable<DataManagement> {
        return this.apiService.scalaApiDbGetdataPost(dataManagement);
    }

    deleteTableFromDMById(
        id: string,
        organisation: string
    ): Observable<DeleteTableFromDMById200Response> {
        return this.apiService.deleteTableFromDMById(id, organisation);
    }

    addNewTableToDM(
        organisation: string,
        dataManagement: DataManagement
    ): Observable<DataManagement> {
        return this.apiService.addNewTableToDM(organisation, dataManagement);
    }

    scalaApiDbInsertdataPost(
        dataManagementExtra: DataManagementExtra,
        organisation?: string
    ): Observable<ScalaApiDbExporttablePost200Response> {
        return this.apiService.scalaApiDbInsertdataPost(dataManagementExtra);
    }

    scalaApiDbDeletetablePost(
        dataManagement: DataManagement,
        organisation?: string
    ): Observable<ScalaApiDbExporttablePost200Response> {
        return this.apiService.scalaApiDbDeletetablePost(dataManagement);
    }
}

@Injectable({ providedIn: 'root' })
export class BimApiService2 implements BimApiService {
    constructor(
        private apiService: DmTransactionsService,
        private oldApiService: BimApiService1,
        private injector: Injector
    ) {}

    updateDataTableList(
        organisation: string,
        dataManagement: Array<DataManagement>
    ): Observable<string> {
        return this.oldApiService.updateDataTableList(organisation, dataManagement);
    }

    backendApiFieldsReadFilePost(file?: Blob): Observable<DataManagementField[]> {
        return this.oldApiService.backendApiFieldsReadFilePost(file);
    }

    backendApiDataManagementFieldsGenerateResponsePost(
        file?: Blob,
        data?: DataManagement
    ): Observable<object[]> {
        return this.oldApiService.backendApiDataManagementFieldsGenerateResponsePost(file, data);
    }

    backendApiDataManagementFieldsExportPost(dataManagement: DataManagement): Observable<Blob> {
        return this.oldApiService.backendApiDataManagementFieldsExportPost(dataManagement);
    }

    downloadFieldsTemplate(): Observable<Blob> {
        return this.oldApiService.downloadFieldsTemplate();
    }

    getDataTableList(organisation: string, status: string): Observable<DataManagement[]> {
        return this.oldApiService.getDataTableList(organisation, status);
    }

    scalaApiDbGetdataPost(
        dataManagement: DataManagement,
        organisation: string,
        filterToSystem?: boolean
    ): Observable<any> {
        if (filterToSystem) {
            const sysService = this.injector.get(SysService);
            const system = sysService.getItemById(dataManagement.system);
            return this.apiService.getAllTransactions(organisation, system.title, true);
        }
        return this.apiService.getAllTransactions(organisation);
    }

    deleteTableFromDMById(
        id: string,
        organisation: string
    ): Observable<DeleteTableFromDMById200Response> {
        return this.oldApiService.deleteTableFromDMById(id, organisation);
    }

    addNewTableToDM(
        organisation: string,
        dataManagement: DataManagement
    ): Observable<DataManagement> {
        return this.oldApiService.addNewTableToDM(organisation, dataManagement);
    }

    scalaApiDbInsertdataPost(
        dataManagementExtra: DataManagementExtra,
        organisation?: string
    ): Observable<ScalaApiDbExporttablePost200Response> {
        return this.apiService
            .updateTransaction(organisation, dataManagementExtra.data as any[])
            .pipe(
                map(response => {
                    return {
                        status: 'OK',
                    };
                })
            );
    }

    scalaApiDbExporttablePost(
        dataManagement: DataManagement
    ): Observable<ScalaApiDbExporttablePost200Response> {
        return this.oldApiService.scalaApiDbExporttablePost(dataManagement);
    }

    scalaApiDbDeletetablePost(
        dataManagement: DataManagement,
        organisation?: string
    ): Observable<ScalaApiDbExporttablePost200Response> {
        return this.apiService.deleteTransaction(organisation, dataManagement.system);
    }
}

@Injectable({ providedIn: 'root' })
export class BimApiService3 implements BimApiService {
    constructor(
        private apiService: DmCostElementsService,
        private oldApiService: BimApiService1,
        private injector: Injector
    ) {}

    updateDataTableList(
        organisation: string,
        dataManagement: Array<DataManagement>
    ): Observable<string> {
        return this.oldApiService.updateDataTableList(organisation, dataManagement);
    }

    backendApiFieldsReadFilePost(file?: Blob): Observable<DataManagementField[]> {
        return this.oldApiService.backendApiFieldsReadFilePost(file);
    }

    backendApiDataManagementFieldsGenerateResponsePost(
        file?: Blob,
        data?: DataManagement
    ): Observable<object[]> {
        return this.oldApiService.backendApiDataManagementFieldsGenerateResponsePost(file, data);
    }

    backendApiDataManagementFieldsExportPost(dataManagement: DataManagement): Observable<Blob> {
        return this.oldApiService.backendApiDataManagementFieldsExportPost(dataManagement);
    }

    downloadFieldsTemplate(): Observable<Blob> {
        return this.oldApiService.downloadFieldsTemplate();
    }

    getDataTableList(organisation: string, status: string): Observable<DataManagement[]> {
        return this.oldApiService.getDataTableList(organisation, status);
    }

    scalaApiDbGetdataPost(
        dataManagement: DataManagement,
        organisation: string,
        filterToSystem?: boolean
    ): Observable<any> {
        return this.apiService.getAllCostElements(organisation, true);
    }

    deleteTableFromDMById(
        id: string,
        organisation: string
    ): Observable<DeleteTableFromDMById200Response> {
        return this.oldApiService.deleteTableFromDMById(id, organisation);
    }

    addNewTableToDM(
        organisation: string,
        dataManagement: DataManagement
    ): Observable<DataManagement> {
        return this.oldApiService.addNewTableToDM(organisation, dataManagement);
    }

    scalaApiDbInsertdataPost(
        dataManagementExtra: DataManagementExtra,
        organisation?: string
    ): Observable<ScalaApiDbExporttablePost200Response> {
        return this.apiService.updateCostElements(dataManagementExtra.data as any[]).pipe(
            map(response => {
                return {
                    status: 'OK',
                };
            })
        );
    }

    scalaApiDbExporttablePost(
        dataManagement: DataManagement
    ): Observable<ScalaApiDbExporttablePost200Response> {
        return this.oldApiService.scalaApiDbExporttablePost(dataManagement);
    }

    scalaApiDbDeletetablePost(
        dataManagement: DataManagement,
        organisation?: string
    ): Observable<ScalaApiDbExporttablePost200Response> {
        return this.apiService.deleteCostElement(organisation);
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiServiceFactory {
    constructor(
        private apiServiceA: BimApiService1,
        private apiServiceB: BimApiService2,
        private apiServiceC: BimApiService3
    ) {}

    getApiService(dm?: DataManagement): BimApiService {
        if (!dm || dm.category !== 'S') {
            return this.apiServiceA;
        } else if (dm.category === 'S' && dm.titel.includes('cost_elements')) {
            return this.apiServiceC;
        } else {
            return this.apiServiceB;
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class BimService {
    _data: WritableSignal<Array<DataManagement>> = signal([]);

    private destroy$ = new Subject<void>();

    get data() {
        return this._data;
    }

    // protected apiService: BimApiService;

    private apiServiceFactory: ApiServiceFactory;
    protected apiService(dm?: DataManagement): BimApiService {
        return this.apiServiceFactory.getApiService(dm);
    }

    protected businessProcessTaskService: BusinessProcessTaskService;
    protected saveButtonService: SaveButtonService;
    protected orgService: OrganisationFilterService;

    private selectedOrg: string;

    constructor(protected injector: Injector) {
        this.apiServiceFactory = this.injector.get(ApiServiceFactory);
        this.businessProcessTaskService = this.injector.get(BusinessProcessTaskService);
        this.saveButtonService = this.injector.get(SaveButtonService);
        this.orgService = this.injector.get(OrganisationFilterService);

        effect(() => {
            if (this.selectedOrg !== this.orgService.selectedOrganisation()) {
                this.selectedOrg = this.orgService.selectedOrganisation();
                this.getData();
            }
        });
        this.getData();
    }

    getData(filter?: string): void {
        const organisationId = this.orgService.selectedOrganisation();
        this.apiService()
            .getDataTableList(organisationId, filter)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data: Array<DataManagement>) => {
                this._data.update(() => [...data]);
            });
    }

    getDbData(data: DataManagement, filterToSystem?: boolean): Observable<any> {
        const organisationId = this.orgService.selectedOrganisation();
        return this.apiService(data).scalaApiDbGetdataPost(data, organisationId, filterToSystem);
    }

    deleteById(id: string): Observable<any> {
        const organisationId = this.orgService.selectedOrganisation();
        return this.apiService().deleteTableFromDMById(id, organisationId);
    }

    copy(data: DataManagement) {
        new Promise(resolve => setTimeout(resolve, 1)).then(() => {
            data.id = undefined;
            const version = data.version + 'n';
            data.version = version;
            this.save(data)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    const currentData = this._data();
                    const updatedData = [...currentData, data];
                    this._data.set(updatedData);
                });
        });
    }

    save(data: any): Observable<DataManagement> {
        const organisationId = this.orgService.selectedOrganisation();
        return this.apiService(data).addNewTableToDM(organisationId, data);
    }

    insertData(data: DataManagementExtra): Observable<ScalaApiDbExporttablePost200Response> {
        const organisationId = this.orgService.selectedOrganisation();
        return this.apiService(data.table).scalaApiDbInsertdataPost(data, organisationId);
    }

    exportData(data: DataManagement): Observable<ScalaApiDbExporttablePost200Response> {
        return this.apiService(data).scalaApiDbExporttablePost(data);
    }

    deleteData(data: DataManagement): Observable<ScalaApiDbExporttablePost200Response> {
        const organisationId = this.orgService.selectedOrganisation();
        return this.apiService(data).scalaApiDbDeletetablePost(data, organisationId);
    }

    updateDataTableList(data: DataManagement) {
        const organisationId = this.orgService.selectedOrganisation();
        this.apiService(data)
            .updateDataTableList(organisationId, [data])
            .pipe(takeUntil(this.destroy$))
            .subscribe((res: any) => {
                const currentData = this._data();
                const itemIndex = currentData.findIndex(item => item.id === data.id);
                if (itemIndex !== -1) {
                    currentData[itemIndex] = data;

                    this._data.update(() => [...currentData]);
                }

                this.saveButtonService.hasUnsavedChanges = false;
            });
    }
}
