import { Inject, Injectable, signal } from '@angular/core';
import { ContextMenuItemClickData } from '@em4cloud/my-cdk';
import { DataManagementAPIService } from './data-management.api.service';
import { DataManagement } from 'app/api';
import { ButtonType } from 'app/core/dialogBuilder/dialog-builder.models';
import { DataFormComponent } from './data-form/data-form.component';
import { DialogBuilderService } from 'app/core/dialogBuilder/dialog-builder.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AlertService } from 'app/core/alert/alert.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { DataManagementTreeFlatNode } from '@em4cloud/data-management-tree';

@Injectable({ providedIn: 'root' })
export class DataManagementMenuService {
    constructor(
        private _apiService: DataManagementAPIService,
        private _dialogService: DialogBuilderService,
        private _router: Router,
        @Inject(DOCUMENT) private document: Document,
        private clipboard: Clipboard,
        private _alertService: AlertService
    ) {}

    menuItemClicked(
        $event: ContextMenuItemClickData<DataManagementTreeFlatNode>,
        item: DataManagement
    ) {
        if ($event.menuItem.id === 'copy') this._apiService.copy(item);
        else if ($event.menuItem.id === 'delete') this._apiService.deleteEntry(item);
        else if ($event.menuItem.id === 'addChild') this.newTable($event.node);
        else if ($event.menuItem.id === 'addVersion') this.newTable($event.node, true);
        else if ($event.menuItem.id === 'shareLink') {
            this.shareProcess(
                this.buildNodeRelativeLink($event.node as DataManagementTreeFlatNode)
            );
        }
    }

    shareProcess(link: string) {
        this.clipboard.copy(this.document.location.host + link);
        this._alertService.basicAlert('actions', 'linkCopiedClipboard');
    }

    buildNodeRelativeLink(node: DataManagementTreeFlatNode | any): string {
        let ids: string[] = [];
        ids.push('dtId=' + node.id);
        let path = '?' + ids.join('&');
        const baseUrl = this._router.url.split(/[?#;]/)[0];
        const finalUrl = baseUrl + path;
        return finalUrl;
    }

    newTable(node: DataManagementTreeFlatNode, newVersion: boolean = false) {
        let componentData: Partial<DataManagement> = { id: null };
        if (newVersion) {
            componentData = {
                ...componentData,
                area: node.meta?.area,
                wbs: node.meta?.wbs,
            };
        } else {
            componentData = {
                ...componentData,
                area: node.meta?.area,
            };
        }

        this._dialogService.openDialog({
            descriptor: {
                executeActionSignal: signal(null),

                dialogSize: 'l',
                header: {
                    title: 'Create',
                    showCloseButton: true,
                },
                content: {
                    componentConfig: {
                        component: DataFormComponent,
                        componentData: {
                            model: componentData,
                        },
                    },
                },
                actions: {
                    dialogActions: [
                        {
                            code: 'save',
                            color: 'primary',
                            style: ButtonType.raised,
                            title: 'Save',
                            manualClose: true,
                        },
                        {
                            code: 'close',
                            color: 'secondary',
                            style: ButtonType.simple,
                            title: 'Close',
                        },
                    ],
                },
            },
        });
    }
}
