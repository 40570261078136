import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    Signal,
    computed,
    effect,
    input,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
    DataManagementTreeComponent,
    DataManagementTreeFlatNode,
} from '@em4cloud/data-management-tree';
import { ContextMenuItemClickData } from '@em4cloud/my-cdk';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TRANSLOCO_SCOPE, Translation, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { BpmUniversalDataObject, DataManagement } from 'app/api';
import { SaveButtonService } from 'app/core/save-button/save-button.service';
import { SysService } from 'app/modules/org-sys-area/services/sys.service';
import { Observable, Subject, map } from 'rxjs';
import { DataManagementMenuService } from '../data-management-menu.service';
import { DataManagementService } from '../data-management.service';

@Component({
    selector: 'data-tree',
    templateUrl: './data-tree.component.html',
    standalone: true,
    imports: [DataManagementTreeComponent, FuseAlertComponent, TranslocoModule],
    styleUrls: ['./data-tree.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: 'data',
        },
    ],
})
export class DataTreeComponent implements AfterViewInit {
    editMode = input(false);
    isSelectionDialog = input();

    dataManagementData: Signal<DataManagement[]>;
    mdoSystems: Signal<BpmUniversalDataObject[]>;

    translocoContent: Signal<Translation>;

    selectedNodeId = computed(() => this._dataManagementService.selectedDataTable()?.id, {
        equal: (a, b) => a === b,
    });

    hasSubscribedToQueryChange: boolean = false;

    constructor(
        private _router: Router,
        private route: ActivatedRoute,
        private _destroyRef: DestroyRef,
        private sysService: SysService,
        private _translocoService: TranslocoService,
        private _saveButtonService: SaveButtonService,
        private _dataManagementService: DataManagementService,
        private _dataManagementMenuService: DataManagementMenuService
    ) {
        this.mdoSystems = computed(() => {
            return this.sysService.state();
        });
        this.dataManagementData = computed(this._dataManagementService.state);

        this.translocoContent = toSignal(this._translocoService.selectTranslation('data'));

        effect(() => {
            if (this._dataManagementService.state()?.length && !this.hasSubscribedToQueryChange) {
                this.route.queryParamMap
                    .pipe(
                        takeUntilDestroyed(this._destroyRef),
                        map((params: ParamMap) => {
                            setTimeout(() => {
                                this.handleTreeNodeSelection(params);
                            }, 100);
                            return params;
                        })
                    )
                    .subscribe();
            }
        });
    }
    ngAfterViewInit(): void {}

    handleTreeNodeSelection(params: ParamMap) {
        this.hasSubscribedToQueryChange = true;
        if (params.has('dtId')) {
            const id = params.get('dtId');
            // set selected process
            const item = this._dataManagementService.state().find(x => x.id === id);

            this._dataManagementService.selectedDataTable = item;
        }
    }

    canChangeTreeNodeSelection(node: DataManagementTreeFlatNode): Observable<boolean> {
        let canChange = new Subject<boolean>();

        if (!this.isSelectionDialog()) {
            const finalUrl = this._dataManagementMenuService.buildNodeRelativeLink(node);
            // set id of the selected node in the url
            this._router.navigateByUrl(finalUrl);

            let continueWithoutSaving = this._saveButtonService.continueWithoutSaving
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe({
                    next: value => {
                        canChange.next(value);
                        continueWithoutSaving.unsubscribe();
                    },
                });
        } else {
            // setTimeout so that it is emitted after the observable is returned
            setTimeout(() => {
                canChange.next(true);
            });
        }
        return canChange.asObservable();
    }

    treeNodeSelected(node: DataManagementTreeFlatNode) {
        const item = this._dataManagementService.state().find(x => x.id === node.id);
        if (!item) return;

        this._dataManagementService.selectedDataTable = item;
    }

    menuItemClicked($event: ContextMenuItemClickData<DataManagementTreeFlatNode>) {
        if (!$event.node) return;
        const item = this._dataManagementService.state().find(x => x.id === $event.node.id);
        this._dataManagementMenuService.menuItemClicked($event, item);
    }
}
