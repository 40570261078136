import { DestroyRef, inject, Injectable, Injector } from '@angular/core';
import { DataManagement, DataManagementField } from 'app/api';
import { AlertService } from 'app/core/alert/alert.service';
import { BimService } from 'app/services/bim/bim.service';
import {
    ConfirmationService,
    ConfirmationCloseAction,
} from 'app/services/confirmation/confirmation.service';
import { DataManagementService } from './data-management.service';
// import { environment } from 'environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DataManagementTableDetailsFormService } from './data-management-table-details-form.service';
import { cloneDeep } from 'lodash';
import { DataFieldsApiService } from './data-fields/data-fields.api.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataManagementAPIService extends BimService {
    /**
     * Inject services
     */
    private _dialogService = inject(ConfirmationService);
    private _alertService = inject(AlertService);
    //private _dataFieldApi = inject(DataFieldsApiService);

    constructor(
        protected _injector: Injector,
        protected _destroyRef: DestroyRef
    ) {
        super(_injector);
    }

    /**
     * Copy from existing data. Generate new id
     */
    copy(entry: DataManagement) {
        entry = { ...entry, id: undefined };
        this.save(entry).subscribe({
            next: response => {
                const currentData = this._data();
                const updatedData = [...currentData, response];
                this._data.set(updatedData);
                this._alertService.successAlert('data', 'alerts.copyTableSuccessMessage');
            },
            error: error => {
                this._alertService.errorAlert('data', 'alerts.generalErrorMessage');
            },
        });
    }

    /**
     * Delete selected data row by id
     */
    deleteEntry(entry: DataManagement) {
        const config = {
            title: 'alerts.deleteTableTitle',
            message: 'alerts.deleteTableMessage',
            actions: {
                confirm: {
                    label: 'delete',
                },
            },
        };
        this._dialogService
            .open(config)
            .afterClosed()
            .subscribe((action: ConfirmationCloseAction) => {
                if (action === 'confirmed') {
                    this.deleteById(entry.id).subscribe(data => {
                        const currentData = structuredClone(
                            this._data().filter(d => d.id !== entry.id)
                        );
                        this._data.update(() => [...currentData]);

                        this._injector.get(DataManagementService).selectedDataTable = null;
                        this._alertService.successAlert('data', 'alerts.deleteTableSuccessMessage');
                    });
                }
            });
    }

    /**
     *
     * Save data table entry
     */
    saveEntry(entry: Partial<DataManagement>) {
        this.save(entry).subscribe({
            next: (response: DataManagement) => {
                const currentData = this._data();
                const updatedData = [...currentData, response];
                this._data.set(updatedData);
                this._alertService.successAlert('data', 'alerts.saveTableSuccessMessage');
            },
            error: error => {
                this._alertService.errorAlert('data', 'alerts.generalErrorMessage');
            },
        });
    }

    /**
     * Update the table entry
     */
    updateEntry(entry: DataManagement) {
        const organisationId = this.orgService.selectedOrganisation();

        this.apiService(entry)
            .updateDataTableList(organisationId, [entry])
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe({
                next: (res: any) => {
                    const currentData = this._data();
                    const itemIndex = currentData.findIndex(item => item.id === entry.id);
                    if (itemIndex !== -1) {
                        currentData[itemIndex] = entry;
                        this._data.update(() => [...currentData]);
                    }
                    this._injector.get(DataManagementTableDetailsFormService).originalFormValue =
                        entry;
                    this._injector.get(DataManagementService).selectedDataTable = entry;
                    this._alertService.successAlert('data', 'alerts.dataUpdateSuccessMessage');
                },
                error: error => {
                    this._alertService.errorAlert('data', 'alerts.generalErrorMessage');
                },
            });
    }

    /**
     * Export table from schema
     */
    exportTableFromSchema() {
        let data = cloneDeep(
            this._injector.get(DataManagementTableDetailsFormService).originalFormValue()
        );
        this.apiService(data)
            .scalaApiDbExporttablePost(data)
            //.scalaApiIdDbExporttablePost(data)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe({
                next: res => {
                    data.status = 'A';
                    this.updateEntry(data);
                },
                error: error => {
                    this._alertService.errorAlert('data', 'alerts.generalErrorMessage');
                },
            });
    }

    uploadTableFromCsv(csv: any): Observable<DataManagementField[]> {
        return this.apiService().backendApiFieldsReadFilePost(csv); //subscribe(response => {
    }

    uploadValueTableFromCsv(csv: any, data: DataManagement) {
        return this.apiService().backendApiDataManagementFieldsGenerateResponsePost(csv, data);
    }

    exportExcelFile(data: DataManagement) {
        return this.apiService(data)
            .backendApiDataManagementFieldsExportPost(data)
            .subscribe(data => {
                const blob = new Blob([data], { type: 'application/octet-stream' });

                const downloadURL = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadURL;
                link.download = `fields_data_template.xlsx`;
                link.click();

                setTimeout(() => {
                    window.URL.revokeObjectURL(downloadURL);
                }, 100);
            });
    }

    downloadTemplateFile() {
        this.apiService()
            .downloadFieldsTemplate()
            .subscribe(data => {
                const blob = new Blob([data], { type: 'application/octet-stream' });

                const downloadURL = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadURL;
                link.download = `fields_data_template.xlsx`;
                link.click();

                setTimeout(() => {
                    window.URL.revokeObjectURL(downloadURL);
                }, 100);
            });
    }
}
