import * as i0 from '@angular/core';
import { signal, computed, Injectable, EventEmitter, input, effect, Component, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { TreeViewComponent } from '@em4cloud/tree-view';
var TypeOfDataManagementTreeNode;
(function (TypeOfDataManagementTreeNode) {
  TypeOfDataManagementTreeNode["Folder"] = "Folder";
  TypeOfDataManagementTreeNode["TableEntity"] = "TableEntity";
})(TypeOfDataManagementTreeNode || (TypeOfDataManagementTreeNode = {}));
var FolderType;
(function (FolderType) {
  FolderType["Area"] = "Area";
  FolderType["System"] = "Syste";
  FolderType["TableEntity"] = "TableEntity";
})(FolderType || (FolderType = {}));
var DataTableCategory;
(function (DataTableCategory) {
  DataTableCategory["All"] = "All";
  DataTableCategory["Dimension"] = "D";
  DataTableCategory["Help"] = "H";
  DataTableCategory["Fact"] = "F";
  DataTableCategory["Result"] = "R";
})(DataTableCategory || (DataTableCategory = {}));
const UNASSIGNED_SYSTEM_NAME = 'UNASSIGNED';
class DataManagementTreeService {
  constructor() {
    this.SEPARATOR = '&#x29BF;';
    this.dataManagementData = signal([]);
    this.mdoSystems = signal([]);
    this._treeNodes = computed(() => this.createDataTree());
  }
  get treeNodes() {
    return this._treeNodes;
  }
  createDataTree() {
    // STEP 1 - IDENTIFYING AREAS AND GROUPINGS FOR EACH TABLE ENTRY
    let systemNodes = this.createSystemTree();
    // STEP 2 - CREATING RELATIONSHIP BETWEEN FOLDERS AND TABLE ENTRIES
    let systemFolders = cloneDeep(this.mdoSystems()); // all area folder names
    if (!systemFolders.some(a => a.id === UNASSIGNED_SYSTEM_NAME)) {
      systemFolders.push({
        children: [],
        id: UNASSIGNED_SYSTEM_NAME,
        name: UNASSIGNED_SYSTEM_NAME,
        internalId: UNASSIGNED_SYSTEM_NAME
      });
    }
    let finalTree;
    // STEP 3 - CREATING THE FINAL TREE
    finalTree = systemFolders.map(this.createSystemFolder.bind(this, [systemNodes])).filter(value => value && value.children.length > 0);
    return finalTree;
  }
  createSystemTree() {
    let systemTree = new Map();
    // separating each table entry based on area
    this.dataManagementData().forEach(entry => {
      // using 'unassigned' folder for entries that has no 'area' assigned
      const systemName = this.checkIfSystemExists(entry.system, this.mdoSystems()) ? entry.system : UNASSIGNED_SYSTEM_NAME;
      // adding a new 'area' to the map if it isn't initialized
      if (!systemTree.has(systemName)) systemTree.set(systemName, []);
      // finding node with wbs in the area node if already exists in tree data
      // adding wbs node if doesn't exist
      let wbsNode = systemTree.get(systemName).find(node => node.title === entry.wbs);
      if (!wbsNode) {
        wbsNode = this.createTableFolderNode(entry);
        systemTree.get(systemName).push(wbsNode);
      }
      // adding table entity node
      wbsNode.children.push(this.createTableNode(entry));
    });
    return systemTree;
  }
  checkIfSystemExists(systemName, systems) {
    if (systemName) {
      for (let index = 0; index < systems.length; index++) {
        const system = systems[index];
        if (system.id === systemName) {
          return true;
        } else if (system.children?.length) {
          const result = this.checkIfSystemExists(systemName, system.children);
          if (result) {
            return true;
          }
        }
      }
    }
    return false;
  }
  createSystemFolder([systemNodes], system) {
    const systemNode = this.createSystemFolderNode(system);
    if (system.children) {
      systemNode.children = system.children.map(this.createSystemFolder.bind(this, [systemNodes]));
      systemNode.children = systemNode.children.filter(value => value && value.children.length > 0);
    }
    if (!systemNode.children || systemNode.children.length === 0) systemNode.children = [];
    if (systemNodes.get(system.id)) {
      systemNode.children.push(...systemNodes.get(system.id));
    }
    return systemNode;
  }
  createSystemFolderNode(system) {
    return {
      id: system.internalId,
      title: system['title'] ?? UNASSIGNED_SYSTEM_NAME,
      children: [],
      meta: {
        nodeType: TypeOfDataManagementTreeNode.Folder,
        folderType: FolderType.System,
        system: system.id
      }
    };
  }
  createTableFolderNode(tableEntry) {
    return {
      id: tableEntry.system + '' + tableEntry.wbs,
      title: tableEntry.wbs,
      children: [],
      meta: {
        nodeType: TypeOfDataManagementTreeNode.Folder,
        folderType: FolderType.TableEntity,
        wbs: tableEntry.wbs,
        system: tableEntry.system
      }
    };
  }
  createTableNode(tableEntry) {
    return {
      id: tableEntry.id,
      title: tableEntry.titel,
      meta: {
        nodeType: TypeOfDataManagementTreeNode.TableEntity,
        category: tableEntry.category
      }
    };
  }
  static {
    this.ɵfac = function DataManagementTreeService_Factory(t) {
      return new (t || DataManagementTreeService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DataManagementTreeService,
      factory: DataManagementTreeService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DataManagementTreeService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class DataManagementTreeComponent {
  constructor(_treeService) {
    this._treeService = _treeService;
    this.treeNodeSelection = new EventEmitter();
    this.menuItemClick = new EventEmitter();
    this.canChangeTreeNodeSelection = input();
    this.translocoContent = input({});
    this.editMode = input(false);
    this.dataManagementData = input([]);
    this.mdoSystems = input([]);
    this.selectedNodeId = input('');
    this.transformFunction = (node, level) => {
      const getIcon = node => {
        let icon = '';
        if (node.meta?.nodeType === TypeOfDataManagementTreeNode.Folder) {
          if (node.meta?.folderType === FolderType.System) icon = 'mat_solid:folder';else icon = 'folder';
        } else if (node.meta?.nodeType === TypeOfDataManagementTreeNode.TableEntity) {
          switch (node.meta?.category) {
            case DataTableCategory.Dimension:
              icon = 'heroicons_outline:table-cells';
              break;
            case DataTableCategory.Fact:
              icon = 'heroicons_outline:rectangle-group';
              break;
            case DataTableCategory.Help:
              icon = 'heroicons_outline:magnifying-glass-circle';
              break;
            case DataTableCategory.Result:
              icon = 'heroicons_outline:newspaper';
              break;
          }
        }
        return icon;
      };
      return {
        checkbox: node.meta?.nodeType !== TypeOfDataManagementTreeNode.Folder,
        icon: getIcon(node),
        expandable: !!node.children && node.children.length > 0,
        title: node.title,
        level: level,
        id: node.id,
        meta: {
          ...node.meta
        }
      };
    };
    this.treeData = computed(this._treeService.treeNodes);
    // setting tree configuration
    this.setTreeConfiguration();
    // initializing data from parent
    effect(() => {
      this._treeService.dataManagementData.set(this.dataManagementData());
      this._treeService.mdoSystems.set(this.mdoSystems());
    }, {
      allowSignalWrites: true
    });
  }
  setTreeConfiguration() {
    this.treeConfig = computed(() => {
      let config = {
        allowNodeDeselection: false,
        canChangeTreeNodeSelection: this.canChangeTreeNodeSelection(),
        contextMenuConfig: {
          enableContextMenu: true,
          contextMenuItems: [{
            id: 'copy',
            title: this.translocoContent()?.['copy'],
            isVisible: node => {
              return node.meta?.nodeType === TypeOfDataManagementTreeNode.TableEntity && this.editMode();
            }
          }, {
            id: 'delete',
            title: this.translocoContent()?.['delete'],
            isVisible: node => {
              return node.meta?.nodeType === TypeOfDataManagementTreeNode.TableEntity && this.editMode();
            }
          }, {
            id: 'shareLink',
            title: this.translocoContent()?.['shareLink'],
            isVisible: node => {
              return node.meta?.nodeType === TypeOfDataManagementTreeNode.TableEntity;
            }
          }, {
            id: 'addChild',
            title: this.translocoContent()?.['addTable'],
            isVisible: node => {
              return this.editMode() && node.meta?.folderType === FolderType.System;
            }
          }, {
            id: 'addVersion',
            title: this.translocoContent()?.['addVersion'],
            isVisible: node => {
              return this.editMode() && node.meta?.folderType === FolderType.TableEntity;
            }
          }]
        }
      };
      return config;
    });
  }
  treeNodeSelected(node) {
    this.treeNodeSelection.emit(node);
  }
  menuItemClicked($event) {
    this.menuItemClick.emit($event);
  }
  static {
    this.ɵfac = function DataManagementTreeComponent_Factory(t) {
      return new (t || DataManagementTreeComponent)(i0.ɵɵdirectiveInject(DataManagementTreeService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DataManagementTreeComponent,
      selectors: [["lib-data-management-tree"]],
      inputs: {
        canChangeTreeNodeSelection: [i0.ɵɵInputFlags.SignalBased, "canChangeTreeNodeSelection"],
        translocoContent: [i0.ɵɵInputFlags.SignalBased, "translocoContent"],
        editMode: [i0.ɵɵInputFlags.SignalBased, "editMode"],
        dataManagementData: [i0.ɵɵInputFlags.SignalBased, "dataManagementData"],
        mdoSystems: [i0.ɵɵInputFlags.SignalBased, "mdoSystems"],
        selectedNodeId: [i0.ɵɵInputFlags.SignalBased, "selectedNodeId"]
      },
      outputs: {
        treeNodeSelection: "treeNodeSelection",
        menuItemClick: "menuItemClick"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 4,
      consts: [[3, "data", "selectNodeId", "treeConfig", "transformFunction", "treeNodeSelectionChange", "menuItemClick"]],
      template: function DataManagementTreeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "custom-tree", 0);
          i0.ɵɵlistener("treeNodeSelectionChange", function DataManagementTreeComponent_Template_custom_tree_treeNodeSelectionChange_0_listener($event) {
            return ctx.treeNodeSelected($event);
          })("menuItemClick", function DataManagementTreeComponent_Template_custom_tree_menuItemClick_0_listener($event) {
            return ctx.menuItemClicked($event);
          });
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("data", ctx.treeData)("selectNodeId", ctx.selectedNodeId)("treeConfig", ctx.treeConfig())("transformFunction", ctx.transformFunction);
        }
      },
      dependencies: [TreeViewComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DataManagementTreeComponent, [{
    type: Component,
    args: [{
      selector: 'lib-data-management-tree',
      standalone: true,
      imports: [TreeViewComponent],
      template: "<custom-tree\n    [data]=\"treeData\"\n    [selectNodeId]=\"selectedNodeId\"\n    [treeConfig]=\"treeConfig()\"\n    [transformFunction]=\"transformFunction\"\n    (treeNodeSelectionChange)=\"treeNodeSelected($event)\"\n    (menuItemClick)=\"menuItemClicked($event)\"></custom-tree>\n"
    }]
  }], () => [{
    type: DataManagementTreeService
  }], {
    treeNodeSelection: [{
      type: Output
    }],
    menuItemClick: [{
      type: Output
    }]
  });
})();

/*
 * Public API Surface of data-management-tree
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DataManagementTreeComponent, DataManagementTreeService, DataTableCategory, FolderType, TypeOfDataManagementTreeNode, UNASSIGNED_SYSTEM_NAME };
